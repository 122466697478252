<template>
    <div>
        <!--新媒体数据-->
        <div class="swwdData">
            <!--顶部导航-->
            <div class="topNav">
                <div class="topNavleft">
                    解读
                </div>
                <!--切换-->
                <div class="sortTabs">
                    <div class="sortTabsList"
                         v-for="(item,index) in newSort"
                         :key="index"
                         @click="sortTabsHandle(item.index,'new')"
                         :class="item.index==keydata.newIndex?'sortTabsListCheck':''">
                        {{ item.title }}
                    </div>
                </div>
            </div>
            <!--新媒体数据展示-->
            <div class="newDataShow" v-loading="loadingObj.dataLoading">
                <!--数据展示头部 -->
                <div class="newDataHead">
                    <!--头部名 标题-->
                    <div class="newDataHeadTitle">标题</div>
                    <!--头部名 文号-->
                    <div class="newDataHeadGzh">公众号</div>
                    <!--头部名 阅读量-->
                    <div class="newDataHeadGYdl">阅读量</div>
                    <!--头部名 发布日期-->
                    <div class="newDataHeadTime">发布日期</div>
                </div>
                <!--数据列表-->
                <div class="newDataListBox">
                    <div class="showDataList" v-for="(item,index) in dataObj.newDataList" :key="index">
                        <!--标题名-->
                        <!-- <el-tooltip class="item" effect="dark" :content="item.title.replace(/<[^>]+>/g,'')" placement="top-start"> -->
                            <!--<div class="showDataListTitle" v-html="item.title.replace(/<[^>]+>/g,'')" @click="dataHandle(item.url)"></div>-->
                            <div class="showDataListTitle" v-html="item.title" @click="dataHandle(item.url,index)"></div>
                        <!-- </el-tooltip> -->
                        <!--公众号-->
                        <div class="showDataListGzh">{{ item.account }}</div>
                        <!--阅读量-->
                        <div class="showDataListYdl">{{ item.readNum }}</div>
                        <!--发布日期-->
                        <div class="showDataListTime">
                            {{ (item.publishTime&&item.publishTime!=''&&item.publishTime.length>10)?item.publishTime.slice(0, 10):'-'}}
                        </div>
                    </div>
                    <!--无数据时的展示-->
                    <div class="showDataListNot" v-if="dataObj.newDataList.length==0&&noDataType_jd==''&&loadingObj.dataLoading == false">
                        抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                    </div>
                    <div class="showDataListNot" v-if="dataObj.newDataList.length==0&&noDataType_jd=='articleNo'&&loadingObj.dataLoading == false">
                        抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                    </div>
                </div>
                <!--分页-->
                <div  class="fenyebox" v-loading="loadingObj.fenyeLoading" v-if="dataObj.newDataList.length!=0">
                    <el-pagination
                        background
                        layout="prev, pager,jumper, next"
                        :page-size=30
                        :current-page.sync = "keydata.pageNew"
                        @size-change="handleSizeChangeNew"
                        @current-change="handleCurrentChangeNew"
                        :total="dataObj.newDataTotal">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--遮罩-->
        <div class="zhezhao" v-if="isZhezhaoOld">
            <div class="zhezhaoWhiteBox">
                <div class="zhezhaotext">验证成功!</div>
                <div class="zhezhaotext">即将为您跳转!</div>
                <div class="zhezhaotextBig">{{ readyNum }}</div>
            </div>
        </div>
        <!--遮罩-->
        <div class="newZhaoZhao" v-if="isZhezhao">
            <div class="zztextBox">
                验证成功,正在为您跳转...
            </div>
        </div>
    </div>
</template>

<script>

import {doGet, doGetCode, doGetPhb} from "@/api/request";

export default {
    name: "zxzcMore",
    computed: {
        searchDataFN() {
            return this.$store.state.searchData
        },
        //是否在验证验证码页面点击确认
        codeOKFN(){
            return this.$store.state.codeOK
        }
    },
    watch: {
        searchDataFN(curVal, oldVal) {
            this.keydata.pageNew = 1;
            this.keydata.searchKey = curVal.key;
            this.keydata.searchType = curVal.type;
            this.getNewData()
        },
        codeOKFN(cur){
            if(cur.status == 'ok'){
                this.isZhezhao = true;
                console.log(this.$store.state.detailInfo)
                let isPhb = this.$store.state.detailInfo.isPhb
                let that = this
                this.readyJumpTimer = setInterval(function(){
                    that.readyNum -=1;
                    if(that.readyNum <= 0){
                        clearInterval(that.readyJumpTimer);
                        that.isZhezhao = false;
                        that.readyNum = 3
                        if(isPhb == true){
                            let idOrUl = that.$store.state.detailInfo.Oid
                            that.goDetailPHB(idOrUl)
                        }else{
                            let idOrUl = that.$store.state.detailInfo.Oid
                            let oType = that.$store.state.detailInfo.Otype
                            that.goDetail(idOrUl,oType)
                        }
                    }
                },1000)
            }
        }
    },
    data(){
        return{
            //参数
            keydata:{
                //新媒体选择
                newIndex:1,
                //搜索内容
                searchKey:'',
                //搜索类型
                searchType:'',
                //页码
                pageNew:1,
                //条数
                limit:30,

            },
            //准备跳转
            readyNum:3,
            readyJumpTimer:null,
            isZhezhao:false,
            isZhezhaoOld:false,

            searchSupType:['giao','title','articleNo','keyWord'],
            noDataType_jd:'',
            //数据保存
            dataObj:{
                //税务问答数据 - 答疑
                newDataList:[],
                newDataTotal:0,
            },
            //加载状态
            loadingObj:{
                dataLoading:false,
                fenyeLoading:false,
            },
            //新媒体
            newSort:[
                {
                    //title:'官方新媒体',
                    title:'官方解读',
                    index:1
                },
                {
                    //title:'企业新媒体',
                    title:'企业解读',
                    index:2
                },
                {
                    //title:'专家新媒体',
                    title:'专家解读',
                    index:3
                }
            ]
        }
    },
    created(){
        //是否存在params传值
        if(this.$route.params&&this.$route.params!=''){
            this.keydata.searchKey = this.$route.params.keyWord;
            this.keydata.searchType = this.$route.params.searchType;
        }
        //判读请求分类  不够智能 简单应付 可以提升
        if(this.$store.state.typeData.typeSup == '官方解读'){
            this.keydata.newIndex = 1;
        }else if(this.$store.state.typeData.typeSup == '企业解读'){
            this.keydata.newIndex = 2;
        }else if(this.$store.state.typeData.typeSup == '专家解读'){
            this.keydata.newIndex = 3;
        }
        this.getNewData();
    },
    methods:{
        //分类切换
        sortTabsHandle(index,type){
            if(type=='swwd'){
                this.keydata.pageSwwd = 1;
                this.keydata.swwdIndex = index;
                this.getSwwdData()
            }else if(type == 'zxzc'){
                this.keydata.pageZxzc = 1;
                this.keydata.zxzcIndex = index;
            }else if(type == 'new'){
                this.keydata.pageNew = 1;
                this.keydata.newIndex = index;
                this.getNewData()
            }

            let sSup = '';
            let tnum  = this.keydata.newIndex - 1
            sSup = this.newSort[tnum].title
            let vuexObj = {
                type:8,
                typeSup:sSup
            }
            this.$store.commit('addTypeData',vuexObj)
        },
        //最新政策 - 新媒体
        getNewData(){
            let that = this;
            this.noDataType_jd = 'loading'
            this.loadingObj.dataLoading = true;
            let url ='/retrieve/hotList';

            let data = {
                page: this.keydata.pageNew,
                limit: this.keydata.limit,
            }
            //判断类型

            if(this.keydata.newIndex ==1){
                data.assort = 27;
            }else if(this.keydata.newIndex ==2){
                data.assort =30;
            }else{
                data.assort = 31;
            }
            // if(this.keydata.newIndex ==1){
            //     data.categoryIds = 27;
            // }else if(this.keydata.newIndex ==2){
            //     data.categoryIds =30;
            // }else{
            //     data.categoryIds = 31;
            // }

            let isArticleNo = false
            //搜索
            if(this.keydata.searchType){
                let typeArr = this.searchSupType
                let thatSearchType = this.keydata.searchType
                if(thatSearchType == 3){
                    thatSearchType =1
                }
                data[typeArr[thatSearchType]] = this.keydata.searchKey
                if(typeArr[this.keydata.searchType] == 'articleNo'&&this.keydata.searchKey!=''){
                    isArticleNo = true;
                }
            }

            doGet(url,data).then(res=> {
                if(res.status == 200){
                    if(res.data.status == 0){
                        if(isArticleNo){
                            this.dataObj.newDataList = [];
                            this.dataObj.newDataTotal = 0;
                            this.noDataType_jd = 'articleNo'
                        }else{
                            this.dataObj.newDataList = res.data.rows;
                            this.dataObj.newDataTotal = res.data.total;
                            this.noDataType_jd = ''
                        }

                        if(that.keydata.searchKey&&that.keydata.searchKey!=''){
                            for(let i=0;i<this.dataObj.newDataList.length;i++){
                                //获取当前title
                                let thatTitle = this.dataObj.newDataList[i].title
                                let redText = "<em style='font-style:normal;color:red;'>" + this.keydata.searchKey +"</em>"
                                thatTitle = thatTitle.replaceAll(this.keydata.searchKey,redText);
                                that.dataObj.newDataList[i].title = thatTitle
                            }
                        }
                    }else{
                        console.log('数据返回错误')
                        console.log(res)
                        if(res.data.status == -1&&res.data.msg){
                            this.$message({
                                type:'warning',
                                message:res.data.msg
                            })
                        }
                        this.noDataType_jd = ''
                    }
                }else{
                    console.log('数据请求错误')
                    console.log(res)
                    this.noDataType_jd = ''
                }
                this.loadingObj.dataLoading = false;
            }).catch(error => {
                console.log('服务器请求错');
                /*this.$message({
                    message:'服务器错误,请联系管理员!',
                    type:'error',
                    duration:'6000',
                })*/
                this.loadingObj.dataLoading = false;
                this.noDataType_jd = ''
                console.log(error);
            })
        },
        //新媒体点击详情事件
        dataHandle(tid,index){

            //获取存储里的信息 是否为第一次 因为未做初始存储 获取值为null 如果有值则是判断非第一次
            //let isFirst = sessionStorage.getItem("isFirst");
            let isFirst = 0
            if(isFirst){
                //判断用户存储 cookie信息
                let loginCode = this.$cookies.get('loginCode')
                if(loginCode == null){
                    let detailObj = {
                        Oid:tid,
                        Otype:'',
                        isPhb:true
                    }
                    let ewmObj = {
                        status:true
                    }
                    this.$store.commit('addDetailInfo',detailObj)
                    this.$store.commit('addEwmStatus',ewmObj)
                }else{
                    //获取存储的cookie的code
                    console.log(loginCode)
                    console.log(loginCode.code)
                    let val = loginCode.code
                    //验证是否过期
                    let url = '/exit';
                    let data = val
                    doGetCode(url,data).then(res => {
                        console.log(res)
                        if(res.status == 200){
                            if(res.data.status == 0 ){
                                //存入cookie 过期时间为1天
                                let cookieObj = {
                                    code:val,
                                    expires:1
                                }
                                this.$cookies.set('loginCode',cookieObj)
                                console.log(tid)
                                this.goDetailPHB(tid)
                            }else{
                                //错误提示
                                this.$message.error(res.data.msg);
                                //如果是过期则重新验证
                                if(res.data.status == -1){
                                    let detailObj = {
                                        Oid:tid,
                                        Otype:'',
                                        isPhb:true
                                    }
                                    let ewmObj = {
                                        status:true
                                    }
                                    this.$store.commit('addDetailInfo',detailObj)
                                    this.$store.commit('addEwmStatus',ewmObj)
                                }
                            }
                        }else{
                            this.$message.error('验证码验证失败,请稍后重试!');
                        }
                        this.loading = false
                    }).catch(error => {
                        console.log(error)
                        this.loading = false
                        this.$message.error('验证码验证失败,请稍后重试!');
                    })
                }
            }else{
                sessionStorage.setItem("isFirst", "second");
                this.goDetailPHB(tid)
            }

        },
        //跳转详情
        goDetailPHB(tUrl){
            window.open(tUrl)
        },
        //分页切换 新媒体
        handleSizeChangeNew(val){
            this.keydata.pageNew = val
            this.getNewData();
        },
        handleCurrentChangeNew(val){
            this.keydata.pageNew = val
            this.getNewData();
        },
    }
}
</script>

<style lang="scss" scoped>
/**顶部导航样式 start*/
.topNav{
    width:100%;
    height: 48px;
    box-sizing: border-box;
    border-bottom:2px solid #009cff;
    .topNavleft{
        width:140px;
        height:48px;
        color:#ffffff;
        line-height: 48px;
        text-align: center;
        font-size: 20px;
        float: left;
        background-image: url('~@/assets/images/leftNavImg.png');
    }
    .sortTabs{
        width: calc(100% - 200px);
        float: left;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        .sortTabsList{
            height:48px;
            line-height: 48px;
            color:#888888;
            font-family: SourceHanSansCN-Medium;
            font-size:18px;
            margin-left:25px;
            float: left;
            cursor:pointer;

        }
        .sortTabsListCheck{
            color:#009cff;
        }
    }
    /**更多按钮*/
    .moreData{
        width:32px;
        height: 48px;
        line-height: 48px;
        font-size:16px;
        float: right;
        color:#b8b8b8;
        cursor: pointer;
        overflow: hidden;
        font-weight: bold;
    }
}
/**顶部导航样式 end*/


/**数据列表部分 新媒体 start*/
.newDataShow{
    /**数据展示头部*/
    .newDataHead{
        width:100%;
        height:36px;
        line-height: 36px;
        background: #f0f0f0;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color:#0045ad;
        .newDataHeadTitle{
            width:640px;
            width:calc(100% - 120px - 50px - 120px - 10px);
            float: left;
        }
        .newDataHeadGzh{
            /*width:140px;*/
            width:120px;
            float: left;
            margin-left:10px;
        }
        .newDataHeadGYdl{
            width:50px;
            float: left;
            /*margin-left:20px;*/
        }
        .newDataHeadTime{
            width:120px;
            float: left;
            /*margin-left:20px;*/
        }
    }
    /**数据展示列表*/
    .newDataListBox{
        /*min-height: 60px;*/
        min-height: 34px;
        overflow: hidden;
        /*padding:9px 0 10px 0;*/
        color:#222222;
        cursor: pointer;
        .showDataList{
            height:16px;
            margin-top:18px;
        }
        .showDataListNot{
            height:16px;
            line-height: 16px;
            text-align: center;
            margin-top:18px;
            /*line-height: 50px;*/
        }
        .showDataListTitle{
            width:640px;
            width:calc(100% - 120px - 50px - 120px - 10px);
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            float: left;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .showDataListGzh{
            width:120px;
            float: left;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            margin-left:10px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            text-align: center;
        }
        .showDataListYdl{
            width:50px;
            float: left;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            /*margin-left:20px;*/
            text-align: center;
        }
        .showDataListTime{
            width: 120px;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            float: right;
            text-align: center;
            /*margin-right:10px;*/
            text-align: center;
        }
    }
}
/**数据列表部分 最新政策 end*/
.division{
    width:1050px;
    height:2px;
    background: #f0f0f0;
    margin:20px 0;
}
.fenyebox{
    text-align: center;
    margin-bottom: 2px;
    overflow: hidden;
    margin-top:20px;
}

/**默认样式 */
.focusing {
    border:none;
    outLine :-webkit-focus-ring-color auto 0px;
}

.zhezhao{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background: rgba(0,0,0,0.2);
    .zhezhaoWhiteBox{
        width:500px;
        height:170px;
        background: #ffffff;
        border-radius: 30px;
        margin:200px auto;
        text-align: center;
        .zhezhaotext{
            width:100%;
            height:50px;
            line-height: 50px;
            font-size:16px;
        }
        .zhezhaotextBig{
            width:100%;
            height:50px;
            line-height: 50px;
            font-size:22px;
        }
    }
}
.newZhaoZhao{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    .zztextBox{
        width:300px;
        height:50px;
        line-height: 50px;
        background: rgba(0,0,0,0.8);
        border-radius: 10px;
        margin:200px auto;
        text-align: center;
        color:#ffffff;
    }
}
</style>
