<template>
    <div class="more">
        <swwdMore v-if="type==1" />
        <zxzcMore v-if="type==2" />
        <newMore v-if="type==3" />
    </div>
</template>

<script>
    import swwdMore from "@/views/more/swwdMore"
    import zxzcMore from "@/views/more/zxzcMore"
    import newMore from "@/views/more/newMore"

    export default {
        name: "more",
        components:{
            swwdMore,
            zxzcMore,
            newMore
        },
        data(){
            return{
                type:'',
            }
        },
        created(){
            this.type = this.$route.query.type
        },
        methods:{

        }
    }
</script>

<style scoped>

</style>