<template>
    <div class="home">
        <!--标签数检索-->
        <div class="tagTreeData" v-loading="loadingObj.treeLoading">
            <div class="tagTreeDataList"
                 v-for="(item,index) in dataObj.treeData"
                 :key="index"
                 @click="treeHandle(item.id,index)"
                 :id="index==keydata.tagTreeIndex?'qbColoe':''">
                {{ item.name }}
            </div>
        </div>
        <!--最新政策数据-->
        <div class="swwdData" v-loading="loadingObj.dataLoading">
            <!--最新政策数据展示-->
            <div class="zxzcDataShow">
                <!--数据展示头部 -->
                <div class="zxzcDataHead">
                    <!--头部名 标题-->
                    <div class="zxzcDataHeadTitle">政策标题</div>
                    <!--头部名 文号-->
                    <div class="zxzcDataHeadWh">文号</div>
                    <!--头部名 发布日期-->
                    <div class="zxzcDataHeadTime">发布日期</div>
                </div>
                <!--数据列表-->
                <div class="zxzcDataListBox">
                    <div class="showDataList" v-for="(item,index) in dataObj.dataList" :key="index">
                        <!--标题名-->
                        <!-- <el-tooltip class="item" effect="dark" :content="item.title.replace(/<[^>]+>/g,'')" placement="top-start"> -->
                            <!--2021-04-22暂时去掉描红-->
                            <!--<div class="showDataListTitle" v-html="item.title.replace(/<[^>]+>/g,'')"  @click="dataHandle(item.id)"></div>-->
                            <div class="showDataListTitle" v-html="item.title"  @click="dataHandle(item.id,index)"></div>
                        <!-- </el-tooltip> -->
                        <!--发布文号-->
                        <div class="showDataListWh" v-if="item.article_no&&item.article_no!=''" v-html="item.article_no"></div>
                        <!--<div class="showDataListWh" v-if="item.article_no&&item.article_no!=''" v-html="item.article_no.replace(/<[^>]+>/g,'')"></div>-->
                        <div class="showDataListWh" v-else> - </div>
                        <!--发布日期-->
                        <div class="showDataListTime">{{ item.publish_time }}</div>
                    </div>
                    <!--无数据时的展示-->
                    <div class="showDataListNot" v-if="dataObj.dataList.length==0&&loadingObj.dataLoading == false">
                        抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                    </div>
                </div>
                <!--分页-->
                <div  class="fenyebox" v-loading="loadingObj.fenyeLoading" v-if="dataObj.dataList.length!=0">
                    <el-pagination
                        background
                        layout="prev, pager,jumper, next"
                        :page-size=30
                        :current-page.sync = "keydata.page"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :total="dataObj.dataListTotal">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--遮罩-->
        <div class="zhezhao" v-if="isZhezhaoOld">
            <div class="zhezhaoWhiteBox">
                <div class="zhezhaotext">验证成功!</div>
                <div class="zhezhaotext">即将为您跳转!</div>
                <div class="zhezhaotextBig">{{ readyNum }}</div>
            </div>
        </div>
        <!--遮罩-->
        <div class="newZhaoZhao" v-if="isZhezhao">
            <div class="zztextBox">
                验证成功,正在为您跳转...
            </div>
        </div>
    </div>
</template>

<script>

import {doGet, doGetCode} from "@/api/request";
const tagTreeId = process.env.NODE_ENV == 'development' ? "996423" : "996423";

export default {
    name: "home",
    computed: {
        searchDataFN() {
            return this.$store.state.searchData
        },
        //是否在验证验证码页面点击确认
        codeOKFN(){
            return this.$store.state.codeOK
        }
    },
    watch: {
        searchDataFN(curVal, oldVal) {
            this.keydata.page=1;
            this.keydata.searchKey = curVal.key;
            this.keydata.searchType = curVal.type;
            this.getTreeData();
            //this.getData(tagTreeId)
            this.getData(this.keydata.nowId)
        },
        codeOKFN(cur){
            if(cur.status == 'ok'){
                this.isZhezhao = true;
                console.log(this.$store.state.detailInfo)
                let isPhb = this.$store.state.detailInfo.isPhb
                let that = this
                this.readyJumpTimer = setInterval(function(){
                    that.readyNum -=1;
                    if(that.readyNum <= 0){
                        clearInterval(that.readyJumpTimer);
                        that.isZhezhao = false;
                        that.readyNum = 3
                        if(isPhb == true){
                            let idOrUl = that.$store.state.detailInfo.Oid
                            that.goDetailPHB(idOrUl)
                        }else{
                            let idOrUl = that.$store.state.detailInfo.Oid
                            let oType = that.$store.state.detailInfo.Otype
                            that.goDetail(idOrUl,oType)
                        }
                    }
                },1000)
            }
        }
    },
    data(){
        return{
            //参数
            keydata:{
                //标签数选择
                tagTreeIndex:0,
                //页码
                page:1,
                //条数
                size:30,
                //当前id
                nowId:tagTreeId,
                //搜索内容
                searchKey:'',
                //搜索类型
                searchType:'',
            },
            //准备跳转
            readyNum:3,
            readyJumpTimer:null,
            isZhezhao:false,
            isZhezhaoOld:false,

            searchSupType:['giao','title','articleNo','keyWord'],
            //数据保存
            dataObj:{
                treeData:[],
                dataList:[],
                dataListTotal:0,
            },
            //加载状态
            loadingObj:{
                dataLoading:false,
                treeLoading:false,
                fenyeLoading:false,
            },
        }
    },
    created() {
        //获取vuex保存信息
        this.keydata.searchKey = this.$store.state.searchData.key;
        this.keydata.searchType = this.$store.state.searchData.type;
        this.getTreeData();
        this.getData(this.keydata.nowId)
    },
    methods:{
        //获取标签树
        getTreeData(){
            this.loadingObj.treeLoading = true;
            let url ='/retrieve/getTagTreeByConditions';
            let data = {
                // categories:'taxesType',
                level:1,
                parentId:'6558575'
            }
            doGet(url,data).then(res => {
                if(res.status == 200){
                    if(res.data.status == 0){
                        let newArr = res.data.data[0].children;
                        let newObj  = {
                            id: 0,
                            name: "全部"
                        }
                        //头部添加 全部
                        newArr.unshift(newObj)
                        this.dataObj.treeData = newArr
                    }else{
                        console.log('数据返回错误')
                        console.log(res)
                    }
                }else{
                    console.log('数据请求错误')
                    console.log(res)
                }
                this.loadingObj.treeLoading = false;
            }).catch(error => {
                console.log('服务器请求错');
                /*this.$message({
                    type:'error',
                    message:'数据升级中,请稍后重试!',
                    duration:'5000',
                })*/
                this.loadingObj.treeLoading = false;
                console.log(error);
            })
        },
        //标签数列表点击事件
        treeHandle(tid,index){
            this.keydata.tagTreeIndex = index;
            this.keydata.page=1;
            if(tid!=0){
                this.getData(tid)
                this.keydata.nowId = tid;
            }else{
                this.getData(tagTreeId)
                this.keydata.nowId = tagTreeId
            }

        },

        //获取数据
        getData(tid){
            this.loadingObj.dataLoading = true;
            let url ='/retrieve/getLaws';
            let data = {
                page: this.keydata.page,
                size: 30,
                operator: true,
                isIncludeFiles: 0,
                tagTreeIds:tid,
                searchType: 'time'
            }
            //搜索
            if(this.keydata.searchType){
                let typeArr = this.searchSupType
                data[typeArr[this.keydata.searchType]] = this.keydata.searchKey
            }
            doGet(url,data).then(res => {
                if(res.status == 200){
                    if(res.data.status == 0){
                        this.dataObj.dataList = res.data.data.data
                        this.dataObj.dataListTotal = res.data.data.total
                    }else{
                        console.log('数据返回错误',res)
                    }
                }else{
                    console.log('数据请求错误',res)
                }
                this.loadingObj.dataLoading = false;
            }).catch(error => {
                /*this.$message({
                    type:'error',
                    message:'数据升级中,请稍后重试!'
                })*/
                this.loadingObj.dataLoading = false;
                console.log(error)
            })
        },
        //点击详情事件
        dataHandle(tid,index){
            //获取存储里的信息 是否为第一次 因为未做初始存储 获取值为null 如果有值则是判断非第一次
            //let isFirst = sessionStorage.getItem("isFirst");
            let isFirst = 0
            if(isFirst){
                //判断用户存储 cookie信息
                let loginCode = this.$cookies.get('loginCode')
                if(loginCode == null){
                    let detailObj = {
                        Oid:tid,
                        Otype:'',
                        isPhb:false
                    }
                    let ewmObj = {
                        status:true
                    }
                    this.$store.commit('addDetailInfo',detailObj)
                    this.$store.commit('addEwmStatus',ewmObj)
                }else{
                    //获取存储的cookie的code
                    console.log(loginCode)
                    console.log(loginCode.code)
                    let val = loginCode.code
                    //验证是否过期
                    let url = '/exit';
                    let data = val
                    doGetCode(url,data).then(res => {
                        console.log(res)
                        if(res.status == 200){
                            if(res.data.status == 0 ){
                                //存入cookie 过期时间为1天
                                let cookieObj = {
                                    code:val,
                                    expires:1
                                }
                                this.$cookies.set('loginCode',cookieObj)
                                this.goDetail(tid)
                            }else{
                                //错误提示
                                this.$message.error(res.data.msg);
                                //如果是过期则重新验证
                                if(res.data.status == -1){
                                    let detailObj = {
                                        Oid:tid,
                                        Otype:'',
                                        isPhb:false
                                    }
                                    let ewmObj = {
                                        status:true
                                    }
                                    this.$store.commit('addDetailInfo',detailObj)
                                    this.$store.commit('addEwmStatus',ewmObj)
                                }
                            }
                        }else{
                            this.$message.error('验证码验证失败,请稍后重试!');
                        }
                        this.loading = false
                    }).catch(error => {
                        console.log(error)
                        this.loading = false
                        this.$message.error('验证码验证失败,请稍后重试!');
                    })
                }
            }else{
                sessionStorage.setItem("isFirst", "second");
                this.goDetail(tid)
            }

        },
        //跳转详情
        goDetail(tid,type){
            //存储cookie操作
            let cookieObj  = {
                type:'税种政策',
            }
            // let setCookie = JSON.stringify(cookieObj)
            // console.log(JSON.stringify(cookieObj))
            this.$cookies.set('addressInfo',cookieObj)

            let obj = {
                id:tid,
                site:'detail',
                keyWord:this.keydata.searchKey
            }
            if(this.keydata.searchType == 2){
                obj.wh = this.keydata.searchKey
            }
            let routeData = this.$router.resolve({
                name: "Detail",
                query:obj
            });
            window.open(routeData.href, '_blank');
        },

        //分页切换
        handleSizeChange(){
            this.keydata.page = val
            this.getData(this.keydata.nowId);
        },
        //
        handleCurrentChange(val){
            this.keydata.page = val
            this.getData(this.keydata.nowId);
        }
    }
}
</script>
<style lang="scss" scoped>

.tagTreeData{
    font-family: SourceHanSansCN-Medium;
    overflow: hidden;
    margin-bottom:10px;
    .tagTreeDataList{
        display: inline-block;
        /*margin-right:16px;*/
        margin-right:24px;
        color:#888888;
        /*font-size: 18px;*/
        font-size: 16px;
        float: left;
        padding: 8px 0;
        cursor: pointer;
    }
    #qbColoe{
        color:#009cff;
    }
}

/**数据列表部分 最新政策 start*/
.zxzcDataShow{
    /**数据展示头部*/
    .zxzcDataHead{
        width:100%;
        height:36px;
        line-height: 36px;
        background: #f0f0f0;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color:#0045ad;
        .zxzcDataHeadTitle{
            width:calc(100% - 120px - 160px - 20px);
            float: left;
        }
        .zxzcDataHeadWh{
            /*width:240px;*/
            width:160px;
            float: left;
            margin-left:20px;
        }
        .zxzcDataHeadTime{
            width:120px;
            float: left;
            /*margin-left:20px;*/
        }
    }
    /**数据展示列表*/
    .zxzcDataListBox{
        /*min-height: 60px;*/
        min-height: 34px;
        overflow: hidden;
        /*padding:9px 0 10px 0;*/
        color:#222222;
        cursor: pointer;
        .showDataListNot{
            height:16px;
            line-height: 16px;
            text-align: center;
            margin-top:18px;
            /*line-height: 50px;*/
        }
        .showDataList{
            height:16px;
            margin-top:18px;
        }
        .showDataListTitle{
            /*width:640px;*/
            width:calc(100% - 120px - 160px - 20px);
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            float: left;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .showDataListWh{
            /*width:240px;*/
            width:160px;
            float: left;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            /*text-align: center;*/
            font-size: 16px;
            margin-left:20px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .showDataListWhc{
            /*width:240px;*/
            width:160px;
            float: left;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            text-align: center;
            font-size: 16px;
            margin-left:20px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .showDataListTime{
            width: 120px;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            float: right;
            text-align: center;
            /*margin-right:10px;*/
        }
    }
}
/**数据列表部分 最新政策 end*/


.fenyebox{
    text-align: center;
    margin-bottom: 2px;
    overflow: hidden;
    margin-top:20px;
}


.zhezhao{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background: rgba(0,0,0,0.2);
    .zhezhaoWhiteBox{
        width:500px;
        height:170px;
        background: #ffffff;
        border-radius: 30px;
        margin:200px auto;
        text-align: center;
        .zhezhaotext{
            width:100%;
            height:50px;
            line-height: 50px;
            font-size:16px;
        }
        .zhezhaotextBig{
            width:100%;
            height:50px;
            line-height: 50px;
            font-size:22px;
        }
    }
}
.newZhaoZhao{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    .zztextBox{
        width:300px;
        height:50px;
        line-height: 50px;
        background: rgba(0,0,0,0.8);
        border-radius: 10px;
        margin:200px auto;
        text-align: center;
        color:#ffffff;
    }
}
</style>
