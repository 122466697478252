<template>
  <div class="detail">
    <!--顶部部分 标题 发文机关 发文字号 发文日期 效力范围-->
    <div class="detailTop" v-loading="dataLoading">
      <!--标题-->
      <div class="title" v-html="title"></div>
      <div class="xia" v-if="xialist.length > 0" @click="xiazai">下载</div>
      <!--发文机关 发文字号 发文日期 效力范围-->
      <div class="detailInfo">
        <p class="pView" v-if="fwjg && fwjg != ''">
          <span class="leftName">发文机关：</span>
          <span>{{ fwjg }}</span>
        </p>
        <p class="pView" v-if="fwzh && fwzh != ''">
          <span class="leftName">发文字号：</span>
          <span v-html="fwzh"></span>
        </p>
        <p class="pView" v-if="fwrq && fwrq != '' && data_type != 'files'">
          <span class="leftName">发文日期：</span>
          <span>{{ fwrq }}</span>
        </p>
        <p class="pView" v-if="fwfw && fwfw != '' && data_type == 'fagui'">
          <span class="leftName">发文范围：</span>
          <span id="xlfw">{{ faguiStatus[fwfw] }}</span>
        </p>
      </div>
    </div>
    <!--内容展示部分-->
    <div class="detailContent" v-html="content"></div>
    <!--关联数据-->
    <div class="guanlianData">
      <!--附件-->
      <div class="guanlianBox" v-if="files.length != 0">
        <div class="guanlianNav">附件</div>
        <div class="guanlianList">
          <div
            class="glFiles"
            v-for="(item, index) in files"
            :key="index"
            @click="filesHandle(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <!--关联原文-->
      <div class="guanlianBox" v-if="gistInfo.length != 0" style="display:none">
        <div class="guanlianNav">关联原文</div>
        <div class="guanlianList">
          <div
            class="glFiles"
            v-for="(item, index) in gistInfo"
            :key="index"
            @click="filesHandle(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <!--附件下载-->
      <div class="guanlianBox" v-if="AssetsInfo && AssetsInfo.length != 0">
        <div class="guanlianNav">附件下载</div>
        <div class="guanlianList">
          <div
            class="glFiles"
            v-for="(item, index) in AssetsInfo"
            :key="index"
            @click="filesDownHandle(item)"
          >
            {{ item.filename }}
          </div>
        </div>
      </div>

      <!--原文链接-->
      <div class="guanlianBox" v-if="thatUrl != ''">
        <div class="guanlianNav">原文链接</div>
        <div class="guanlianList">
          <div class="glFiles">
            http://www.chinatax.gov.cn/chinatax/n363/c5161493/content.html
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
String.prototype.replaceAll = function(s1, s2) {
  return this.replace(new RegExp(s1, 'gm'), s2)
}

//接口
import { doGet, doPostDetail, fjlist } from '@/api/request'

export default {
  name: 'detail',
  data() {
    return {
      //传递的id
      tid: '',
      keyWord: '',
      wh: '',
      //标题
      title: '',
      //发文机关
      fwjg: '',
      //发文字号
      fwzh: '',
      //发文日期
      fwrq: '',
      //发文范围
      fwfw: '',
      //类型
      data_type: '',
      //内容
      content: '',
      //附件list
      files: [],
      //关联原文
      gistInfo: [],
      //附件下载
      AssetsInfo: [],
      //原文链接
      thatUrl: '',
      //法规状态
      faguiStatus: {
        1: '有效法规',
        2: '失效法规',
        3: '部分失效',
        5: '时效性',
      },
      dataLoading: false,
      xialist: [],
    }
  },
  created() {
    this.tid = this.$route.query.id
    //用作描红 2021-04-22暂定去掉描红
    this.keyWord = this.$route.query.keyWord
    this.wh = this.$route.query.wh
    //this.keyWord = '';
    //获取数据
    this.getDetailData(this.tid)
    this.getxia()
  },
  methods: {
    //获取数据
    getDetailData(tid) {
      this.dataLoading = true
      let url = '/retrieve/wxmpInfo'
      let data = {
        id: tid,
      }
      if (this.keyWord != '') {
        data.keyWord = this.keyWord
      }
      doPostDetail(url, data)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.status == 0) {
              if (res.data.data) {
                //标题
                this.title = res.data.data.data[0].title
                //发文机关
                this.fwjg = res.data.data.data[0].source
                //发文字号
                this.fwzh = res.data.data.data[0].article_no
                //判断当前是否描红
                if (this.fwzh) {
                  if (this.fwzh.indexOf(this.wh) != -1) {
                    console.log(this.fwzh.indexOf(this.wh))
                    let newStr =
                      "<em style='font-style:normal;color:red;'>" +
                      this.wh +
                      '</em>'
                    console.log(newStr)
                    this.fwzh = this.fwzh.replaceAll(this.wh, newStr)
                    //console.log(this.article_no)
                  }
                }

                //发文日期
                this.fwrq = res.data.data.data[0].publish_time
                //发文范围
                this.fwfw = res.data.data.data[0].status
                //内容
                this.content = res.data.data.data[0].content_formatted

                //table
                let tableReplace = this.content
                if (tableReplace.indexOf('<table width="') != -1) {
                  while (tableReplace.indexOf('<table width="') != -1) {
                    //获取宽度
                    let tWidth = 860
                    //截取
                    //当 tabled的宽度大于999 或小于100时 会导致标签元素丢失 可通过计算width里的宽度是否符合标准完成弹性的计算
                    let tLength = tableReplace.indexOf('<table width="')
                    console.log(tLength)
                    let oldStr = tableReplace.substr(tLength, 18)
                    let newStr = '<table id="tt" width="' + tWidth
                    tableReplace = tableReplace.replace(oldStr, newStr)
                    this.content = tableReplace
                  }
                } else {
                  this.content = tableReplace
                }

                //类型
                this.data_type = res.data.data.data[0].data_type
                //关联附件
                if (this.data_type != 'files') {
                  this.files = res.data.data.data[0].files
                } else {
                  this.gistInfo = res.data.data.data[0].GistInfo
                }
                //附件下载
                this.AssetsInfo = res.data.data.data[0].AssetsInfo
                this.$nextTick(() => {
                  this.addStyle()
                })
                this.dataLoading = false
              } else {
                this.$message({
                  message: '数据为null',
                  type: 'warning',
                })
                this.dataLoading = false
              }
            } else {
              console.log('数据返回错误!')
              console.log(res)
              this.dataLoading = false
            }
          } else {
            console.log('数据请求错误!')
            console.log(res)
            this.dataLoading = false
          }
          console.log(res)
        })
        .catch((error) => {
          /*this.$message({
                        message:'数据升级中!请稍后重试~',
                        type:'warning',
                    })*/
          this.dataLoading = false
          console.log(error)
        })
    },
    //点击详情事件
    filesHandle(tid) {
      let obj = {
        id: tid,
        site: 'detail',
      }

      let routeData = this.$router.resolve({
        name: 'Detail',
        query: obj,
      })
      window.open(routeData.href, '_blank')
    },
    filesDownHandle(item) {
      let files = item.url
      console.log(files)
      window.open('/webfile/' + files)
    },
    //获取数据里的某些标签并添加样式和点击事件
    addStyle() {
      let that = this
      let changeDiv = document.getElementsByClassName('goto-article')
      Array.from(changeDiv).forEach((item) => {
        item.style.color = 'blue'
        item.style.cursor = 'pointer'
        item.style.textDecoration = 'underline'
        item.onclick = function(param) {
          let ggID = item.getAttribute('data-goto-article-id')
          that.dataHandle(ggID)
        }
      })
    },
    //点击详情事件
    dataHandle(tid) {
      let obj = {
        id: tid,
        site: 'detail',
      }
      let routeData = this.$router.resolve({
        name: 'Detail',
        query: obj,
      })
      window.open(routeData.href, '_blank')
    },
    getxia() {
      //获取下载的附件地址
      let data = {
        contentId: this.tid,
      }
      fjlist(data).then((res) => {
        this.xialist = res.data.page.list
      })
    },
    xiazai() {
      //下载附件
      window.open('/webfile/' + this.xialist[0].filePath)
    },
  },
}
</script>

<style lang="scss" scoped>
.detailTop {
  /*padding-top:50px;*/
  padding-top: 10px;
  border-bottom: 2px solid #f0f0f0;
  .title {
    line-height: 20px;
    color: #222222;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .detailInfo {
    padding: 0 80px;
    font-size: 16px;
    color: #009cff;
    font-family: SourceHanSansCN-Regular;
    .pView {
      /*padding:9px 0;*/
      margin-bottom: 18px;
    }
    #xlfw {
      color: #ff0066;
    }
  }
}
/**正文部分*/
.detailContent {
  padding-top: 18px;
  padding-left: 24px;
  padding-right: 24px;
}
.detailContent ::v-deep img {
  width: 100%;
}
.detailContent ::v-deep p {
  color: #222222;
  line-height: 28px;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
}

/**关联部分  start*/
.guanlianData {
  margin-top: 35px;
  overflow: hidden;
  .guanlianBox {
    overflow: hidden;
    margin-bottom: 16px;
    .guanlianNav {
      /*height: 40px;*/
      /*line-height: 40px;*/
      height: 48px;
      line-height: 48px;
      width: 100%;
      box-sizing: border-box;
      padding-left: 20px;
      background: #009cff;
      color: #ffffff;
      font-size: 18px;
      font-family: SourceHanSansCN-Bold;
    }
    .guanlianList {
      box-sizing: border-box;
      width: 100%;
      padding-left: 20px;
      cursor: pointer;
      .glFiles {
        font-size: 16px;
        color: #009cff;
        font-family: SourceHanSansCN-Medium;
        margin-top: 16px;
        line-height: 26px;
      }
    }
  }
}
/**关联部分  end*/

.detailContent ::v-deep table {
  border-collapse: collapse;
  border-spacing: 0;
}
.detailContent ::v-deep tr {
  border: 1px solid #000000;
}
.detailContent ::v-deep td {
  border: 1px solid #000000;
}
.xia {
  cursor: pointer;
  font-size: 18px;
  text-align: right;
  color: #009cff;
}
</style>
