<template>
    <div>
        <!--最新政策数据-->
        <div class="swwdData">
            <!--顶部导航-->
            <div class="topNav">
                <div class="topNavleft">
                    最新政策
                </div>
                <!--切换-->
                <div class="sortTabs">
                    <div class="sortTabsList"
                         v-for="(item,index) in zxzcSort"
                         :key="index"
                         @click="sortTabsHandle(item.index,'zxzc')"
                         :class="item.index==keydata.zxzcIndex?'sortTabsListCheck':''">
                        {{ item.title }}
                    </div>
                </div>
            </div>
            <!--最新政策数据展示-->
            <div class="zxzcDataShow" v-loading="loadingObj.dataLoading">
                <!--数据展示头部 -->
                <div class="zxzcDataHead">
                    <!--头部名 标题-->
                    <div class="zxzcDataHeadTitle">标题</div>
                    <!--头部名 文号-->
                    <div class="zxzcDataHeadWh">文号</div>
                    <!--头部名 发布日期-->
                    <div class="zxzcDataHeadTime">发布日期</div>
                </div>
                <!--数据列表-->
                <div class="zxzcDataListBox">
                    <div class="showDataList" v-for="(item,index) in dataObj.zxzcDataList" :key="index">
                        <!--标题名-->
                        <!-- <el-tooltip class="item" effect="dark" :content="item.title.replace(/<[^>]+>/g,'')" placement="top-start"> -->
                            <!--<div class="showDataListTitle" v-html="item.title.replace(/<[^>]+>/g,'')" @click="dataHandle(item.id)"></div>-->
                            <div class="showDataListTitle" v-html="item.title" @click="dataHandle(item.id,index)"></div>
                        <!-- </el-tooltip> -->
                        <!--发布文号-->
                        <div v-if="item.article_no&&item.article_no!=''">
                            <!-- <el-tooltip class="item" effect="dark" :content="item.article_no.replace(/<[^>]+>/g,'')" placement="top-start"> -->
                                <!--<div class="showDataListWh" v-html="item.article_no.replace(/<[^>]+>/g,'')"></div>-->
                                <div class="showDataListWh" v-html="item.article_no"></div>
                            <!-- </el-tooltip> -->
                        </div>
                        <div v-else>
                            <div class="showDataListWhc">-</div>
                        </div>

                        <!--发布日期-->
                        <div class="showDataListTime">{{ item.publish_time }}</div>
                    </div>
                    <!--无数据时的展示-->
                    <div class="showDataListNot" v-if="dataObj.zxzcDataList.length==0&&loadingObj.dataLoading == false">
                        抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                    </div>
                </div>
                <!--分页-->
                <div  class="fenyebox" v-loading="loadingObj.fenyeLoading" v-if="dataObj.zxzcDataList.length!=0">
                    <el-pagination
                        background
                        layout="prev, pager,jumper, next"
                        :page-size=30
                        :current-page.sync = "keydata.pageZxzc"
                        @size-change="handleSizeChangeZxzc"
                        @current-change="handleCurrentChangeZxzc"
                        :total="dataObj.zxzcDataTotal">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--遮罩-->
        <div class="zhezhao" v-if="isZhezhaoOld">
            <div class="zhezhaoWhiteBox">
                <div class="zhezhaotext">验证成功!</div>
                <div class="zhezhaotext">即将为您跳转!</div>
                <div class="zhezhaotextBig">{{ readyNum }}</div>
            </div>
        </div>
        <!--遮罩-->
        <div class="newZhaoZhao" v-if="isZhezhao">
            <div class="zztextBox">
                验证成功,正在为您跳转...
            </div>
        </div>
    </div>
</template>

<script>

import {doGet, doGetCode} from "@/api/request";

export default {
    name: "zxzcMore",
    computed: {
        searchDataFN() {
            return this.$store.state.searchData
        },
        //是否在验证验证码页面点击确认
        codeOKFN(){
            return this.$store.state.codeOK
        }
    },
    watch: {
        searchDataFN(curVal, oldVal) {
            this.keydata.pageZxzc = 1;
            this.keydata.searchKey = curVal.key;
            this.keydata.searchType = curVal.type;
            if(this.keydata.zxzcIndex==4){
                this.getZxzcDataJD()
            }else{
                this.getZxzcData();
            }
        },
        codeOKFN(cur){
            if(cur.status == 'ok'){
                this.isZhezhao = true;
                console.log(this.$store.state.detailInfo)
                let isPhb = this.$store.state.detailInfo.isPhb
                let that = this
                this.readyJumpTimer = setInterval(function(){
                    that.readyNum -=1;
                    if(that.readyNum <= 0){
                        clearInterval(that.readyJumpTimer);
                        that.isZhezhao = false;
                        that.readyNum = 3
                        if(isPhb == true){
                            let idOrUl = that.$store.state.detailInfo.Oid
                            that.goDetailPHB(idOrUl)
                        }else{
                            let idOrUl = that.$store.state.detailInfo.Oid
                            let oType = that.$store.state.detailInfo.Otype
                            that.goDetail(idOrUl,oType)
                        }
                    }
                },1000)
            }
        }
    },
    data(){
        return{
            //参数
            keydata:{
                //最新政策选择
                zxzcIndex:1,
                //搜索内容
                searchKey:'',
                //搜索类型
                searchType:'',
                //页码
                pageZxzc:1,
                //条数
                limit:30,

            },
            //准备跳转
            readyNum:3,
            readyJumpTimer:null,
            isZhezhao:false,
            isZhezhaoOld:false,

            searchSupType:['giao','title','articleNo','keyWord'],
            //数据保存
            dataObj:{
                //税务问答数据 - 答疑
                zxzcDataList:[],
                zxzcDataTotal:0,
            },
            //加载状态
            loadingObj:{
                dataLoading:false,
                fenyeLoading:false,
            },
            //最新政策
            zxzcSort:[
                {
                    title:'国家税务法规',
                    index:1
                },
                {
                    title:'中央部门法规',
                    index:2
                },
                {
                    title:'地方财税政策',
                    index:3
                },
                {
                    title:'政策解读',
                    index:4
                }
            ],
        }
    },
    created(){
        //是否存在params传值
        if(this.$route.params&&this.$route.params!=''){
            this.keydata.searchKey = this.$route.params.keyWord;
            this.keydata.searchType = this.$route.params.searchType;
        }
        //判读请求分类  不够智能 简单应付 可以提升
        if(this.$store.state.typeData.typeSup == '国家税务法规'){
            this.keydata.zxzcIndex = 1;
        }else if(this.$store.state.typeData.typeSup == '中央部门法规'){
            this.keydata.zxzcIndex = 2;
        }else if(this.$store.state.typeData.typeSup == "地方财税政策"){
            this.keydata.zxzcIndex = 3;
        }else if(this.$store.state.typeData.typeSup == '政策解读'){
            this.keydata.zxzcIndex = 4;
        }
        if(this.keydata.zxzcIndex==4){
            this.getZxzcDataJD()
        }else{
            this.getZxzcData();
        }

    },
    methods:{
        //分类切换
        sortTabsHandle(index,type){
            if(type=='swwd'){
                this.keydata.pageSwwd = 1;
                this.keydata.swwdIndex = index;
                this.getSwwdData()
            }else if(type == 'zxzc'){
                this.keydata.pageZxzc = 1;
                this.keydata.zxzcIndex = index;
                if(this.keydata.zxzcIndex==4){
                    this.getZxzcDataJD()
                }else{
                    this.getZxzcData()
                }

            }else if(type == 'new'){
                this.keydata.pageNew = 1;
                this.keydata.newIndex = index;
                this.getNewData()
            }


            let sSup = '';
            let tnum  = this.keydata.zxzcIndex - 1
            sSup = this.zxzcSort[tnum].title
            let vuexObj = {
                type:7,
                typeSup:sSup
            }
            this.$store.commit('addTypeData',vuexObj)
        },
        //最新政策数据 - 法规
        getZxzcData(){
            this.loadingObj.dataLoading = true;
            let url = '/retrieve/getLaws';
            let data = {
                size: this.keydata.limit,
                page:this.keydata.pageZxzc,
                operator: true,
                isIncludeFiles: 0,
                searchType: "time"
            };
            //判断类型
            if(this.keydata.zxzcIndex ==1){
                data.ruleName = 'taxLaw-zjfg';
            }else if(this.keydata.zxzcIndex ==2){
                data.ruleName = 'zsg-qtzc';
            }else{
                data.ruleName = 'zsg-dfzc';
            }
            //搜索
            if(this.keydata.searchType){
                let typeArr = this.searchSupType
                data[typeArr[this.keydata.searchType]] = this.keydata.searchKey
            }

            doGet(url,data).then(res => {
                if(res.status == 200){
                    if(res.data.status == 0){
                        this.dataObj.zxzcDataList = res.data.data.data;
                        this.dataObj.zxzcDataTotal = res.data.data.total
                    }else{
                        console.log('数据返回错误')
                        console.log(res)
                    }
                }else{
                    console.log('数据请求错误')
                    console.log(res)
                }
                this.loadingObj.dataLoading = false;
            }).catch(error => {
                console.log('服务器请求错');
                /*this.$message({
                    message:'服务器错误,请联系管理员!',
                    type:'error',
                    duration:'6000',
                })*/
                this.loadingObj.dataLoading = false;
                console.log(error);
            })
        },
        //最新政策 - 解读
        getZxzcDataJD(){
            let url = '/retrieve/getJiedu';
            let data = {
                page:this.keydata.pageZxzc,
                size: this.keydata.limit,
                operator: true,
                isIncludeFiles: 0,
                searchType: "time"
                //jieduTypes: '1,2,3'
            };
            //搜索
            if(this.keydata.searchType){
                let typeArr = this.searchSupType
                data[typeArr[this.keydata.searchType]] = this.keydata.searchKey
            }
            this.loadingObj.dataLoading = true;
            doGet(url,data).then(res => {
                if(res.status == 200){
                    if(res.data.status == 0){
                        this.dataObj.zxzcDataList = res.data.data.data;
                        this.dataObj.zxzcDataTotal = res.data.data.total
                    }else{
                        console.log('数据返回错误')
                        console.log(res)
                    }
                }else{
                    console.log('数据请求错误')
                    console.log(res)
                }
                this.loadingObj.dataLoading = false;
            }).catch(error => {
                console.log('服务器请求错');
                /*this.$message({
                    message:'服务器错误,请联系管理员!',
                    type:'error',
                    duration:'6000',
                })*/
                console.log(error);
                this.loadingObj.dataLoading = false;
            })
            
        },
        //点击详情事件
        dataHandle(tid,index){

            //获取存储里的信息 是否为第一次 因为未做初始存储 获取值为null 如果有值则是判断非第一次
            //let isFirst = sessionStorage.getItem("isFirst");
            let isFirst = 0
            if(isFirst){
                //判断用户存储 cookie信息
                let loginCode = this.$cookies.get('loginCode')
                if(loginCode == null){
                    let detailObj = {
                        Oid:tid,
                        Otype:'',
                        isPhb:false
                    }
                    let ewmObj = {
                        status:true
                    }
                    this.$store.commit('addDetailInfo',detailObj)
                    this.$store.commit('addEwmStatus',ewmObj)
                }else{
                    //获取存储的cookie的code
                    console.log(loginCode)
                    console.log(loginCode.code)
                    let val = loginCode.code
                    //验证是否过期
                    let url = '/exit';
                    let data = val
                    doGetCode(url,data).then(res => {
                        console.log(res)
                        if(res.status == 200){
                            if(res.data.status == 0 ){
                                //存入cookie 过期时间为1天
                                let cookieObj = {
                                    code:val,
                                    expires:1
                                }
                                this.$cookies.set('loginCode',cookieObj)
                                this.goDetail(tid)
                            }else{
                                //错误提示
                                this.$message.error(res.data.msg);
                                //如果是过期则重新验证
                                if(res.data.status == -1){
                                    let detailObj = {
                                        Oid:tid,
                                        Otype:'',
                                        isPhb:false
                                    }
                                    let ewmObj = {
                                        status:true
                                    }
                                    this.$store.commit('addDetailInfo',detailObj)
                                    this.$store.commit('addEwmStatus',ewmObj)
                                }
                            }
                        }else{
                            this.$message.error('验证码验证失败,请稍后重试!');
                        }
                        this.loading = false
                    }).catch(error => {
                        console.log(error)
                        this.loading = false
                        this.$message.error('验证码验证失败,请稍后重试!');
                    })
                }
            }else{
                sessionStorage.setItem("isFirst", "second");
                this.goDetail(tid)
            }
        },
        //跳转详情
        goDetail(tid,type){
            //存储cookie操作
            let cookieObj  = {}
            cookieObj.type = '最新政策';
            cookieObj.typeChild = this.zxzcSort[(this.keydata.zxzcIndex - 1)].title;
            this.$cookies.set('addressInfo',cookieObj)

            let obj = {
                id:tid,
                site:'detail',
                keyWord:this.keydata.searchKey
            }
            if(this.keydata.searchType == 2){
                obj.wh = this.keydata.searchKey
            }
            let routeData = this.$router.resolve({
                name: "Detail",
                query:obj
            });
            window.open(routeData.href, '_blank');
        },
        //分页切换 最新政策
        handleSizeChangeZxzc(val){
            this.keydata.pageZxzc = val
            if(this.keydata.zxzcIndex!=4){
                this.getZxzcData();
            }else{
                this.getZxzcDataJD()
            }
        },
        handleCurrentChangeZxzc(val){
            this.keydata.pageZxzc = val
            if(this.keydata.zxzcIndex!=4){
                this.getZxzcData();
            }else{
                this.getZxzcDataJD()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
/**顶部导航样式 start*/
.topNav{
    width:100%;
    height: 48px;
    box-sizing: border-box;
    border-bottom:2px solid #009cff;
    .topNavleft{
        width:140px;
        height:48px;
        color:#ffffff;
        line-height: 48px;
        text-align: center;
        font-size: 20px;
        float: left;
        background-image: url('~@/assets/images/leftNavImg.png');
    }
    .sortTabs{
        width: calc(100% - 200px);
        float: left;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        .sortTabsList{
            height:48px;
            line-height: 48px;
            color:#888888;
            font-family: SourceHanSansCN-Medium;
            font-size:18px;
            margin-left:25px;
            float: left;
            cursor:pointer;

        }
        .sortTabsListCheck{
            color:#009cff;
        }
    }
    /**更多按钮*/
    .moreData{
        width:32px;
        height: 48px;
        line-height: 48px;
        font-size:16px;
        float: right;
        color:#b8b8b8;
        cursor: pointer;
        overflow: hidden;
        font-weight: bold;
    }
}
/**顶部导航样式 end*/


/**数据列表部分 最新政策 start*/
.zxzcDataShow{
    /**数据展示头部*/
    .zxzcDataHead{
        width:100%;
        height:36px;
        line-height: 36px;
        background: #f0f0f0;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color:#0045ad;
        .zxzcDataHeadTitle{
            width:calc(100% - 120px - 160px - 20px);
            float: left;
        }
        .zxzcDataHeadWh{
            /*width:240px;*/
            width:160px;
            float: left;
            margin-left:20px;
        }
        .zxzcDataHeadTime{
            width:120px;
            float: left;
            /*margin-left:20px;*/
        }
    }
    /**数据展示列表*/
    .zxzcDataListBox{
        /*min-height: 60px;*/
        min-height: 34px;
        overflow: hidden;
        /*padding:9px 0 10px 0;*/
        color:#222222;
        cursor: pointer;
        .showDataListNot{
            height:16px;
            line-height: 16px;
            text-align: center;
            margin-top:18px;
            /*line-height: 50px;*/
        }
        .showDataList{
            height:16px;
            margin-top:18px;
        }
        .showDataListTitle{
            /*width:640px;*/
            width:calc(100% - 120px - 160px - 20px);
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            float: left;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .showDataListWh{
            /*width:240px;*/
            width:160px;
            float: left;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            /*text-align: center;*/
            font-size: 16px;
            margin-left:20px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .showDataListWhc{
            /*width:240px;*/
            width:160px;
            float: left;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            text-align: center;
            font-size: 16px;
            margin-left:20px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .showDataListTime{
            width: 120px;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            float: right;
            text-align: center;
            /*margin-right:10px;*/
        }
    }
}
/**数据列表部分 最新政策 end*/
.fenyebox{
    text-align: center;
    margin-bottom: 2px;
    overflow: hidden;
    margin-top:20px;
}

/**默认样式 */
.focusing {
    border:none;
    outLine :-webkit-focus-ring-color auto 0px;
}
.zhezhao{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background: rgba(0,0,0,0.2);
    .zhezhaoWhiteBox{
        width:500px;
        height:170px;
        background: #ffffff;
        border-radius: 30px;
        margin:200px auto;
        text-align: center;
        .zhezhaotext{
            width:100%;
            height:50px;
            line-height: 50px;
            font-size:16px;
        }
        .zhezhaotextBig{
            width:100%;
            height:50px;
            line-height: 50px;
            font-size:22px;
        }
    }
}
.newZhaoZhao{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    .zztextBox{
        width:300px;
        height:50px;
        line-height: 50px;
        background: rgba(0,0,0,0.8);
        border-radius: 10px;
        margin:200px auto;
        text-align: center;
        color:#ffffff;
    }
}

</style>
