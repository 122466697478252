<template>
    <div class="gywmView">
        <iframe id="iframeView" :src="iframeUrl" frameborder="0"></iframe>
    </div>
</template>

<script>
    export default {
        name: "gywm",
        data(){
            return{
                //iframeUrl:'https://www.shui12366.com/about/',
                iframeUrl:'https://about.shui12366.com/about/',
            }
        }
    }
</script>

<style scoped>
.gywmView{
    width:1220px;
    height:1870px;
    position: relative;
    overflow-y: hidden;
}
#iframeView{
    width: 100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
}

::-webkit-scrollbar{
    width:8px;
    height:8px;
    background:transparent;
}
::-webkit-scrollbar-thumb{
    width:6px;
    height:6px;
    background-color: #5e7286;
    background-color: #c4c4c4;
    border-radius:4px;
}
</style>
