<template>
    <div>
        <!--税务问答数据-->
        <div class="swwdData">
            <!--顶部导航-->
            <div class="topNav">
                <div class="topNavleft">
                    税务问答
                </div>
                <!--切换-->
                <div class="sortTabs">
                    <div class="sortTabsList"
                         v-for="(item,index) in swwdSort"
                         :key="index"
                         @click="sortTabsHandle(item.index,'swwd')"
                         :class="item.index==keydata.swwdIndex?'sortTabsListCheck':''">
                        {{ item.title }}
                    </div>
                </div>
            </div>
            <!--税务问答数据展示-->
            <div class="swwdDataShow" v-loading="loadingObj.dataLoading">
                <!--数据展示头部 -->
                <div class="swwdDataHead">
                    <!--头部名 标题-->
                    <div class="swwdDataHeadTitle">标题</div>
                    <div class="swwdDataHeadDanWei hiddenClass">单位</div>
                    <!--头部名 发布日期-->
                    <div class="swwdDataHeadTime">发布日期</div>
                </div>
                <!--数据列表-->
                <div class="swwdDataListBox">
                    <div class="showDataList" v-for="(item,index) in dataObj.swwdDataList" :key="index">
                        <!--标题名-->
                        <!-- <el-tooltip class="item" effect="dark" :content="item.title.replace(/<[^>]+>/g,'')" placement="top-start"> -->
                            <!--<div class="showDataListTitle" v-html="item.title.replace(/<[^>]+>/g,'')" @click="dataHandle(item.id)"></div>-->
                            <div class="showDataListTitle" v-html="item.title" @click="dataHandle(item.id,index)"></div>
                        <!-- </el-tooltip> -->
                        <!--单位-->
                        <div class="showDataListDanWei hiddenClass">{{ item.source }}</div>
                        <!--发布日期-->
                        <div class="showDataListTime">{{ item.publish_time }}</div>
                    </div>
                    <!--无数据时的展示-->
                    <div class="showDataListNot" v-if="dataObj.swwdDataList.length==0&&noDataType_swwd==''&&loadingObj.dataLoading == false">
                        抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                    </div>
                    <!--无数据时的展示-->
                    <div class="showDataListNot" v-if="dataObj.swwdDataList.length==0&&noDataType_swwd=='articleNo'&&loadingObj.dataLoading == false">
                        抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                    </div>



                </div>
                <!--分页-->
                <div  class="fenyebox" v-loading="loadingObj.fenyeLoading" v-if="dataObj.swwdDataList.length!=0">
                    <el-pagination
                        background
                        layout="prev, pager,jumper, next"
                        :page-size=30
                        :current-page.sync = "keydata.pageSwwd"
                        @size-change="handleSizeChangeSwwd"
                        @current-change="handleCurrentChangeSwwd"
                        :total="dataObj.swwdDataTotal">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--遮罩-->
        <div class="zhezhao" v-if="isZhezhao">
            <div class="zhezhaoWhiteBox">
                <div class="zhezhaotext">验证成功!</div>
                <div class="zhezhaotext">即将为您跳转!</div>
                <div class="zhezhaotextBig">{{ readyNum }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import {doGet, doGetCode} from "@/api/request";

    export default {
        name: "swwdMore",
        computed: {
            searchDataFN() {
                return this.$store.state.searchData
            },
            //是否在验证验证码页面点击确认
            codeOKFN(){
                return this.$store.state.codeOK
            }
        },
        watch: {
            searchDataFN(curVal, oldVal) {
                this.keydata.pageSwwd = 1;
                this.keydata.searchKey = curVal.key;
                this.keydata.searchType = curVal.type;
                this.getSwwdData()
            },
            codeOKFN(cur){
                if(cur.status == 'ok'){
                    this.isZhezhao = true;
                    console.log(this.$store.state.detailInfo)
                    let isPhb = this.$store.state.detailInfo.isPhb
                    let that = this
                    this.readyJumpTimer = setInterval(function(){
                        that.readyNum -=1;
                        if(that.readyNum == 0){
                            clearInterval(that.readyJumpTimer);
                            that.isZhezhao = false;
                            if(isPhb == true){
                                let idOrUl = that.$store.state.detailInfo.Oid
                                that.goDetailPHB(idOrUl)
                            }else{
                                let idOrUl = that.$store.state.detailInfo.Oid
                                let oType = that.$store.state.detailInfo.Otype
                                that.goDetail(idOrUl,oType)
                            }
                        }
                    },1000)
                }
            }
        },
        data(){
            return{
                //参数
                keydata:{
                    //税务问答选择
                    swwdIndex:1,
                    //搜索内容
                    searchKey:'',
                    //搜索类型
                    searchType:'',
                    //页码
                    pageSwwd:1,
                    //条数
                    limit:30,

                },
                //准备跳转
                readyNum:3,
                readyJumpTimer:null,
                isZhezhao:false,

                searchSupType:['giao','title','articleNo','keyWord'],
                noDataType_swwd:'',
                //数据保存
                dataObj:{
                    //税务问答数据 - 答疑
                    swwdDataList:[],
                    swwdDataTotal:0,
                },
                //加载状态
                loadingObj:{
                    fenyeLoading:false,
                },
                //税务问答分类
                swwdSort:[
                    {
                        title:'总局答疑',
                        index:1
                    },
                    {
                        title:'各省答疑',
                        index:2
                    },
                    {
                        title:'专家答疑',
                        index:3
                    }
                ],
            }
        },
        created(){
            //是否存在params传值
            if(this.$route.params&&this.$route.params!=''){
                this.keydata.searchKey = this.$route.params.keyWord;
                this.keydata.searchType = this.$route.params.searchType;
            }
            //判读请求分类  不够智能 简单应付 可以提升
            if(this.$store.state.typeData.typeSup == '总局答疑'){
                this.keydata.swwdIndex = 1;
            }else if(this.$store.state.typeData.typeSup == '各省答疑'){
                this.keydata.swwdIndex = 2;
            }else if(this.$store.state.typeData.typeSup == '专家答疑'){
                this.keydata.swwdIndex = 3;
            }
            this.getSwwdData();
        },
        methods:{
            //分类切换
            sortTabsHandle(index,type){
                if(type=='swwd'){
                    this.keydata.pageSwwd = 1;
                    this.keydata.swwdIndex = index;
                    this.getSwwdData()
                }else if(type == 'zxzc'){
                    this.keydata.pageZxzc = 1;
                    this.keydata.zxzcIndex = index;
                    if(this.keydata.zxzcIndex==4){
                        this.getZxzcDataJD()
                    }else{
                        this.getZxzcData()
                    }

                }else if(type == 'new'){
                    this.keydata.pageNew = 1;
                    this.keydata.newIndex = index;
                    this.getNewData()
                }

                let sSup = '';
                let tnum  = this.keydata.swwdIndex - 1
                sSup =  this.swwdSort[tnum].title
                let vuexObj = {
                    type:6,
                    typeSup:sSup
                }
                this.$store.commit('addTypeData',vuexObj)
            },
            //税务问答数据 答疑
            getSwwdData(){
                this.loadingObj.dataLoading = true;
                this.noDataType_swwd = 'loading'
                let url = '/retrieve/getAnswer';
                let data = {
                    size: this.keydata.limit,
                    page:this.keydata.pageSwwd,
                    operator: true,
                    isIncludeFiles: 0,
                    searchType: "time"
                };
                //判断类型
                data.orDayiTypes = this.keydata.swwdIndex
                // if(this.keydata.swwdIndex ==1){
                //     data.orDayiTypes = 1;
                // }else{
                //     data.orDayiTypes = 2;
                // }
                let isArticleNo = false
                //搜索
                if(this.keydata.searchType){
                    let typeArr = this.searchSupType
                    data[typeArr[this.keydata.searchType]] = this.keydata.searchKey
                    if(typeArr[this.keydata.searchType] == 'articleNo'&&this.keydata.searchKey!=''){
                        isArticleNo = true;
                    }
                }

                doGet(url,data).then(res => {
                    if(res.status == 200){
                        if(res.data.status == 0){
                            if(isArticleNo){
                                this.dataObj.swwdDataList = [];
                                this.dataObj.swwdDataTotal = 0
                                this.noDataType_swwd = 'articleNo'
                            }else{
                                this.dataObj.swwdDataList = res.data.data.data;
                                this.dataObj.swwdDataTotal = res.data.data.total
                                this.noDataType_swwd = '';
                            }

                        }else{
                            console.log('数据返回错误')
                            console.log(res)
                            this.noDataType_swwd = '';
                            if(res.data.status == -1&&res.data.msg){
                                this.$message({
                                    type:'warning',
                                    message:res.data.msg
                                })
                            }
                        }
                    }else{
                        console.log('数据请求错误')
                        console.log(res)
                        this.noDataType_swwd = '';
                    }
                    this.loadingObj.dataLoading = false;
                }).catch(error => {
                    console.log('服务器请求错');
                    /*this.$message({
                        message:'服务器错误,请联系管理员!',
                        type:'error',
                        duration:'6000',
                    })*/
                    this.loadingObj.dataLoading = false;
                    this.noDataType_swwd = '';
                    console.log(error);
                })
            },
            //点击详情事件
            dataHandle(tid,index){
                //获取存储里的信息 是否为第一次 因为未做初始存储 获取值为null 如果有值则是判断非第一次
                //let isFirst = sessionStorage.getItem("isFirst");
                let isFirst = 0
                if(isFirst){
                    //判断用户存储 cookie信息
                    let loginCode = this.$cookies.get('loginCode')
                    if(loginCode == null){
                        let detailObj = {
                            Oid:tid,
                            Otype:'',
                            isPhb:false
                        }
                        let ewmObj = {
                            status:true
                        }
                        this.$store.commit('addDetailInfo',detailObj)
                        this.$store.commit('addEwmStatus',ewmObj)
                    }else{
                        //获取存储的cookie的code
                        console.log(loginCode)
                        console.log(loginCode.code)
                        let val = loginCode.code
                        //验证是否过期
                        let url = '/exit';
                        let data = val
                        doGetCode(url,data).then(res => {
                            console.log(res)
                            if(res.status == 200){
                                if(res.data.status == 0 ){
                                    //存入cookie 过期时间为1天
                                    let cookieObj = {
                                        code:val,
                                        expires:1
                                    }
                                    this.$cookies.set('loginCode',cookieObj)
                                    this.goDetail(tid)
                                }else{
                                    //错误提示
                                    this.$message.error(res.data.msg);
                                    //如果是过期则重新验证
                                    if(res.data.status == -1){
                                        let detailObj = {
                                            Oid:tid,
                                            Otype:'',
                                            isPhb:false
                                        }
                                        let ewmObj = {
                                            status:true
                                        }
                                        this.$store.commit('addDetailInfo',detailObj)
                                        this.$store.commit('addEwmStatus',ewmObj)
                                    }
                                }
                            }else{
                                this.$message.error('验证码验证失败,请稍后重试!');
                            }
                            this.loading = false
                        }).catch(error => {
                            console.log(error)
                            this.loading = false
                            this.$message.error('验证码验证失败,请稍后重试!');
                        })
                    }
                }else{
                    sessionStorage.setItem("isFirst", "second");
                    this.goDetail(tid)
                }


            },
            //跳转详情
            goDetail(tid,type){
                //存储cookie操作
                let cookieObj  = {}
                cookieObj.type = '税务问答';
                cookieObj.typeChild = this.swwdSort[(this.keydata.swwdIndex - 1)].title;
                // let setCookie = JSON.stringify(cookieObj)
                // console.log(JSON.stringify(cookieObj))
                this.$cookies.set('addressInfo',cookieObj)

                let obj = {
                    id:tid,
                    site:'detail',
                    keyWord:this.keydata.searchKey
                }
                let routeData = this.$router.resolve({
                    name: "Detail",
                    query:obj
                });
                window.open(routeData.href, '_blank');
            },
            //分页切换 税务问答
            handleSizeChangeSwwd(){
                this.keydata.pageSwwd = val
                this.getSwwdData();
            },
            handleCurrentChangeSwwd(val){
                this.keydata.pageSwwd = val
                this.getSwwdData();
            },
        }
    }
</script>

<style lang="scss" scoped>

/**顶部导航样式 start*/
.topNav{
    width:100%;
    height: 48px;
    box-sizing: border-box;
    border-bottom:2px solid #009cff;
    .topNavleft{
        width:140px;
        height:48px;
        color:#ffffff;
        line-height: 48px;
        text-align: center;
        font-size: 20px;
        float: left;
        background-image: url('~@/assets/images/leftNavImg.png');
    }
    .sortTabs{
        width: calc(100% - 200px);
        float: left;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        .sortTabsList{
            height:48px;
            line-height: 48px;
            color:#888888;
            font-family: SourceHanSansCN-Medium;
            font-size:18px;
            margin-left:25px;
            float: left;
            cursor:pointer;

        }
        .sortTabsListCheck{
            color:#009cff;
        }
    }
    /**更多按钮*/
    .moreData{
        width:32px;
        height: 48px;
        line-height: 48px;
        font-size:16px;
        float: right;
        color:#b8b8b8;
        cursor: pointer;
        overflow: hidden;
        font-weight: bold;
    }
}
/**顶部导航样式 end*/

/**数据列表部分 税务问答 start*/
.swwdDataShow{
    /**数据展示头部*/
    .swwdDataHead{
        width:100%;
        height:36px;
        line-height: 36px;
        background: #f0f0f0;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color:#0045ad;
        .swwdDataHeadTitle{
            width:calc(100% - 120px - 20px);
            float: left;
            margin-right: 20px;
        }
        .swwdDataHeadDanWei{
            width:200px;
            float: left;
            text-align: center;
            margin-right: 20px;
        }
        .swwdDataHeadTime{
            width:120px;
            float: left;
            text-align: center;
        }
    }
    /**数据展示列表*/
    .swwdDataListBox{
        min-height: 34px;
        overflow: hidden;
        /*padding:9px 0 10px 0;*/
        color:#222222;
        cursor: pointer;
        .showDataList{
            height:16px;
            margin-top:18px;
        }
        .showDataListNot{
            height:16px;
            line-height: 16px;
            text-align: center;
            margin-top:18px;
            /*line-height: 50px;*/
        }
        .showDataListTitle{
            width:calc(100% - 120px - 20px);
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            float: left;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .showDataListDanWei{
            width: 200px;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            float: left;
            margin-left:20px;
            /*text-align: center;*/
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .showDataListTime{
            width: 120px;
            /*height:36px;*/
            /*line-height: 36px;*/
            height: 16px;
            line-height: 16px;
            font-size: 16px;
            float: left;
            margin-left:20px;
            text-align: center;
        }
    }
}
/**数据列表部分 税务问答 end*/

.fenyebox{
    text-align: center;
    margin-bottom: 2px;
    overflow: hidden;
    margin-top:20px;
}
/**默认样式 */
.focusing {
    border:none;
    outLine :-webkit-focus-ring-color auto 0px;
}

.zhezhao{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background: rgba(0,0,0,0.2);
    .zhezhaoWhiteBox{
        width:500px;
        height:170px;
        background: #ffffff;
        border-radius: 30px;
        margin:200px auto;
        text-align: center;
        .zhezhaotext{
            width:100%;
            height:50px;
            line-height: 50px;
            font-size:16px;
        }
        .zhezhaotextBig{
            width:100%;
            height:50px;
            line-height: 50px;
            font-size:22px;
        }
    }
}

.newZhaoZhao{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    .zztextBox{
        width:300px;
        height:50px;
        line-height: 50px;
        background: rgba(0,0,0,0.8);
        border-radius: 10px;
        margin:200px auto;
        text-align: center;
        color:#ffffff;
    }
}

.hiddenClass{
    display: none;
}
</style>
